/* You can add global styles to this file, and also import other style files */

$td-icon-font-path: "/assets/fonts/icons"; // for production, define the base-href when building will allow this path to be configured correctly
$td-font-path: "/assets/fonts/"; // for production, define the base-href when building will allow this path to be configured correctly
/* @import "td-emerald-standards/src/scss/extra/td-spacers.scss"; // import optional spacers helper classes
@import "td-emerald-standards/src/scss/td-emerald-standards.scss"; // import core td-emerald-standards scss
@import "td-emerald-standards/src/scss/extra/td-colour-palette.scss"; // import optional colour palette helper classes
@import "td-emerald-standards/src/scss/extra/td-borders.scss"; // import optional borders helper classes */
/*@import "td-emerald-standards/src/styles/td-emerald-standards.scss";
@import "td-emerald-standards/src/styles/td-emerald-standards-forms.scss";
@import "td-emerald-standards/src/styles/td-emerald-standards-components.scss";
 */
// Use Bootstrap3 version of standards
@import "td-emerald-standards/dist/emerald/assets/css/default.css";
//@import "td-emerald-standards/dist/emerald/assets/css/forms.css";

.mat-dialog-container .mat-dialog-content {
    overflow-x: hidden !important;
}
.custom-dialog-container {
    width: auto !important;
}
.cdk-overlay-pane {
    max-width: none !important;
}